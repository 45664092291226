import React, { useState } from "react";
import styled from "styled-components";
import { lg, sm, xl } from "../../responsive";
import { send } from "emailjs-com";
import contactImg from "../../assets/images/contact.webp";
import { Title } from "../../assets/styles";
import Footer from "./Footer";

const Container = styled.div`
  position: relative;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  ${lg({ display: "none" })}
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  z-index: 2;
`;

/* const Title = styled.h1`
  text-align: center;
  margin-top: 0;
  font-size: 56px;
  font-weight: 200;
  z-index: 2;
  ${lg({ color: "white", fontWeight: 600 })}
  ${sm({ fontSize: "40px" })}
`; */

const Image = styled.img`
  width: 800px;
  -webkit-box-shadow: 5px 10px 15px -5px #000000;
  box-shadow: 5px 10px 15px -5px #000000;
  margin: 60px;
  border-radius: 10px;
  transform: rotate(-6deg);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 90%;
  padding: 20px;
  //-webkit-box-shadow: 5px 10px 15px -5px #000000;
  //box-shadow: 5px 10px 15px -5px #000000;
  ${xl({ width: "80%" })}
  ${lg({ backgroundColor: "white" })}
  ${sm({ gap: "10px" })}
`;

const Input = styled.input`
  padding: 2px 10px;
  width: 100%;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  outline: none;
  ${lg({
    borderTopStyle: "solid",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    borderBottomStyle: "solid",
    padding: "5px 10px",
    fontSize: "16px"
  })}
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
`;

const Select = styled.select`
  padding: 10px 12px;
  border: 1px solid black;
  width: 50%;
  border-radius: 2px;
  font-size: 16px;
  background-color: white;
  color: black;

`;
const Option = styled.option``;

const TextArea = styled.textarea`
  padding: 2px 10px;
  border: 1px solid black;
  border-radius: 50px;
  color: #002a5d;
  width: 100%;
  resize: none;
  border-radius: 2px;
  font-size: 16px;
  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  ${lg({
    borderTopStyle: "solid",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    borderBottomStyle: "solid",
    padding: "5px 10px",
    fontSize: "16px"
  })}
`;
const Button = styled.button`
  width: 50%;
  padding: 10px 15px;
  font-size: 18px;
  border: none;
  background-color: #002a5d;
  color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    background-color: #b1d9f6;
    color: #002a5d;
    border: 1px solid #002a5d;
  }
`;

const Thanks = styled.span`
  color: #002a5d;
  font-size: 18px;
`;

const BgImage = styled.img`
  width: 100%;
  position: absolute;
  top: 100;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  z-index: 1;
  display: none;
  ${lg({ display: "none" })}
`;

const Contact = () => {
  const [toSend, setToSend] = useState({
    from_name: "",
    student_name: "",
    reply_to: "",
    phone_num: "",
    instrument: "",
    experience: "",
    additional_info: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    send("service_le02k4g", "template_urwbj4l", toSend, "KQJnReOqrZAZohurJ")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <Container id="contact">
      <BgImage src={contactImg} alt="" />
      <Wrapper>
        <Left>
          <Image src={contactImg} />
        </Left>
        <Right>
          <Title>ENROL TODAY</Title>
          {!submitted && (
            <Form onSubmit={onSubmit}>
              <Input
                type="text"
                name="from_name"
                value={toSend.from_name}
                placeholder="Name"
                minLength="3"
                required
                onChange={handleChange}
              />
              <Input
                type="text"
                name="student_name"
                value={toSend.student_name}
                placeholder="Student Name"
                minLength="3"
                required
                onChange={handleChange}
              />
              <Input
                type="email"
                name="reply_to"
                value={toSend.reply_to}
                placeholder="Email"
                required
                onChange={handleChange}
              />
              <Input
                type="tel"
                name="phone_num"
                value={toSend.phone_num}
                placeholder="Phone #"
                onChange={handleChange}
              />
              <SelectContainer>
                <Select
                  name="instrument"
                  value={toSend.instrument}
                  required
                  onChange={handleChange}
                >
                  <Option value="guitar">Guitar</Option>
                  <Option value="piano">Piano</Option>
                  <Option value="bass">Bass</Option>
                  <Option value="vocals">Vocals</Option>
                  <Option value="drums">Drums</Option>
                  <Option value="musicTheory">Music Theory</Option>
                  <Option value="songwriting">Songwriting</Option>
                  <Option value="other">Other</Option>
                </Select>
                <Select
                  name="experience"
                  value={toSend.experience}
                  required
                  onChange={handleChange}
                >
                  <Option value="neverPlayed">Never Played Before</Option>
                  <Option value="beginner">Beginner</Option>
                  <Option value="intermediate">Intermediate</Option>
                  <Option value="advanced">Advanced</Option>
                </Select>
              </SelectContainer>
              <TextArea
                name="additional_info"
                value={toSend.additional_info}
                placeholder="Any additional info..."
                onChange={handleChange}
              />
              <Button type="submit">SUBMIT</Button>
            </Form>
          )}
          {submitted && (
            <Thanks>Thanks, we will get back to you shortly :)</Thanks>
          )}
        </Right>
      </Wrapper>
      <Footer />
    </Container>
  );
};

export default Contact;
