import { AttachMoney, Computer, GroupAdd } from "@mui/icons-material";
import { padding } from "@mui/system";
import React from "react";
import styled from "styled-components";
import { pricing } from "../assets/adminData";
import { lg, md, sm, xl } from "../responsive";
import PricingItem from "./PricingItem";
import PricingPolicy from "../assets/files/Pricing-Policy.pdf";
import { Title } from "../assets/styles";

const Container = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d9ecff;
`;

const PricingDesc = styled.span`
  font-size: 20px;
  margin-top: 20px;
  padding: 0 10px;
  text-align: center;
  ${md({ fontSize: "18px" })}
`;

const Anchor = styled.a`
  color: #002a5d;
  text-decoration: none;
`;
const Wrapper = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 50px 30px 30px 30px;
  ${xl({ paddingTop: "30px" })}
  ${md({ flexDirection: "column", paddingTop: "10px" })}
`;

const PromotionsWrapper = styled.div`
  background-color: #002a5d;
  padding: 30px 0px;
  height: 20%;
  width: 65%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  -webkit-box-shadow: 5px 10px 15px -5px #000000;
  box-shadow: 5px 10px 15px -5px #000000;
  transition: all 0.5s ease;
  ${xl({ padding: "20px 100px" })}
  ${md({ display: "none" })}

`;

const PromotionsTitle = styled.h2`
  font-size: 36px;
  font-weight: 300;
`;

const PromotionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PromotionText = styled.span`
  margin-left: 8px;
  font-size: 20px;
  font-weight: 300;
  ${lg({ fontSize: "18px" })}
`;

const Pricing = () => {
  return (
    <Container id="pricing">
      <Title>PRICING</Title>
      <PricingDesc>
        For our full list of pricing and promotions click{" "}
        <Anchor href={PricingPolicy} target="_" rel="noopener noreferrer">
          here.
        </Anchor>
      </PricingDesc>
      <Wrapper>
        {pricing.map((price) => {
          return (
            <PricingItem
              time={price.time}
              cost={price.cost}
              tag={price.tag}
              key={price.time}
            />
          );
        })}
      </Wrapper>
      <PromotionsWrapper>
        <PromotionsTitle>PROMOTIONS</PromotionsTitle>
        <PromotionItem>
          <Computer />
          <PromotionText>Virtual Lessons 5% Discount</PromotionText>
        </PromotionItem>
        <PromotionItem>
          <GroupAdd />
          <PromotionText>
            Students in same house recieve $5 off per hour
          </PromotionText>
        </PromotionItem>
        <PromotionItem>
          <AttachMoney />
          <PromotionText>Discounted semester programs</PromotionText>
        </PromotionItem>
      </PromotionsWrapper>
    </Container>
  );
};

export default Pricing;
