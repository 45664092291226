import React, { useState } from "react";
import styled from "styled-components";
import { lg, sm, xl } from "../responsive";
import QuestionCard from "./QuestionCard";
import { Title } from "../assets/styles";
import { faq } from "../assets/adminData";

const Container = styled.div`
  position: relative;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 75%;
  margin-top: 30px;
`;

function FAQ() {
  const [selectedItem, setSelectedItem] = useState();

  function handleOpen(id) {
    if (id === selectedItem) {
      setSelectedItem(null);
    } else {
      setSelectedItem(id);
    }
  }

  return (
    <Container id="faq">
      <Title>FAQ</Title>
      <QuestionsContainer>
        {faq.map((item) => (
          <QuestionCard
            id={item.id}
            key={item.id}
            question={item.question}
            answer={item.answer}
            open={item.id === selectedItem}
            handleOpen={handleOpen}
          />
        ))}
      </QuestionsContainer>
    </Container>
  );
}

export default FAQ;
