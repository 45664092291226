import { css } from "styled-components"

export const smDesktop = (props) => {
    return css`
        @media only screen and (max-width: 1350px) {
            ${props}
        }
    `
};
export const tablet = (props) => {
    return css`
        @media only screen and (max-width: 900px) {
            ${props}
        }
    `
};
export const mobile = (props) => {
    return css`
        @media only screen and (max-width: 500px) {
            ${props}
        }
    `
};
export const sm = (props) => {
    return css`
        @media only screen and (max-width: 576px) {
            ${props}
        }
    `
};
export const md = (props) => {
    return css`
        @media only screen and (max-width: 768px) {
            ${props}
        }
    `
};
export const lg = (props) => {
    return css`
        @media only screen and (max-width: 992px) {
            ${props}
        }
    `
};
export const xl = (props) => {
    return css`
        @media only screen and (max-width: 1200px) {
            ${props}
        }
    `
};
export const xxl = (props) => {
    return css`
        @media only screen and (max-width: 1400px) {
            ${props}
        }
    `
};