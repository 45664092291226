import { useState } from "react";
import styled from "styled-components";
import Menu from "./components/Menu";
import Topbar from "./components/Topbar";
import Home from "./pages/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const Container = styled.div`
   height: 100vh;
    cursor: default;
    scrollbar-width: none; //for firefox
    &::-webkit-scrollbar{
        display: none;
    }
`;

function App() {
  const [menuOpen, setMenuOpen] = useState(false)
  
  return (
    <Router>

    <Container>
    <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
    </Container>
    </Router>
  );
}

export default App;
