import React from "react";
import styled from "styled-components";
import { lg, sm, xl } from "../../responsive";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 7vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background-color: #002a5d;
  z-index: 2;
`;

const FooterLeft = styled.div`
  padding-left: 10px;
`;
const FooterRight = styled.div`
  padding-right: 10px;
  display: flex;
  ${sm({ flexDirection: "column" })}
`;

const FooterTextContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const FooterText = styled.span`
  font-size: 20px;
  margin-right: 20px;
  ${lg({ fontSize: "14px" })}
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterLeft>
        <FooterText>&copy; Maratta Music</FooterText>
      </FooterLeft>
      <FooterRight>
        <FooterTextContainer>
          <EmailIcon />
          <FooterText>mick@marattamusic.com</FooterText>
        </FooterTextContainer>
        <FooterTextContainer>
          <LocalPhoneIcon />
          <FooterText>416-988-1649</FooterText>
        </FooterTextContainer>
      </FooterRight>
    </FooterContainer>
  );
}

export default Footer;
