import React, { useEffect } from 'react';
import styled from 'styled-components';
import About from '../components/About';
import Intro from '../components/Intro';
import Lessons from '../components/Lessons';
import Pricing from '../components/Pricing';
import Contact from '../components/Contact/Contact';
import { lg, md, sm, xl } from "../responsive";
import FAQ from '../components/FAQ';

const Container = styled.div`
     width: 100%;
    height: calc(100vh - 70px);
    height: calc(var(--windowInnerHeight) - 70px);
    position: relative;
    top: 70px;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; //for firefox
    &::-webkit-scrollbar{
        display: none;
    }

    > * {
        width: 100vw;
        height: calc(var(--windowInnerHeight) - 70px);
        scroll-snap-align: start;
    }

    ${md({ scrollSnapType: "none" })}
`
const Home = () => {
  let windowInnerWidth = 0;
  const handleResize = () => {
    const currentWindowInnerWidth = window.innerWidth;
    if (
      windowInnerWidth === 0 ||
      currentWindowInnerWidth !== windowInnerWidth
    ) {
      windowInnerWidth = currentWindowInnerWidth;
      const windowInnerHeight = window.innerHeight;
      document.documentElement.style.setProperty(
        "--windowInnerHeight",
        `${windowInnerHeight}px`
      );
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize());
  });

  return (
    <Container>
        <Intro />
        <About />
        <Pricing />
        <FAQ />
        <Contact />
    </Container>
  )
}

export default Home