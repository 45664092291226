import { ArrowDownward, KeyboardArrowDown, Phone } from "@mui/icons-material";
import React from "react";
import styled, { keyframes } from "styled-components";
import { lg, sm, xl, xxl } from "../responsive";
import introImg from "../assets/images/header-img.webp"

const Container = styled.div`
  position: relative;
  background-color: #74a9e9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BgImage = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
  z-index: 1;
`;

const PhoneNum = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 22px;
  letter-spacing: 3px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  z-index: 2;
`;

const InnerContainer = styled.div`
  z-index: 99;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  ${xxl({ width: "50%" })}
  ${xl({ width: "60%" })}
  ${lg({ width: "65%" })}
  ${sm({ width: "95%" })}
`;

const Badge = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  -webkit-box-shadow: 5px 10px 15px -5px #000000;
  box-shadow: 5px 10px 15px -5px #000000;
  transition: all 0.5s ease;
  ${lg({ width: "60%" })}

  &:hover {
    background-color: #cee7f0;
  }
`;

const BadgeTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const BadgeAnchor = styled.a`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

const BadgeTextOne = styled.h2`
  font-size: 28px;
  font-weight: 500;
`;

const BadgeTextTwo = styled.span`
  font-style: italic;
`;

const MainText = styled.h2`
  text-align: center;
  color: white;
  letter-spacing: 3px;
  font-size: 42px;
  ${lg({ fontSize: "36px" })}
`;

const flashingAnimation = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.3 }
  100% { opacity: 1; }
`;

const ArrowContainer = styled.div`
  position: absolute;
  bottom: 0;
  animation-name: ${flashingAnimation};
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
`;

const Intro = () => {
  return (
    <Container id="home">
      <BgImage src={introImg} alt="" />
      <PhoneNum>
        <Phone sx={{ marginRight: 1 }} />
        416 - 988 - 1649
      </PhoneNum>
      <InnerContainer>
        <Badge>
          <BadgeAnchor href="#contact" />
          <BadgeTextContainer>
            <BadgeTextOne>ENROL NOW</BadgeTextOne>
            <ArrowDownward />
          </BadgeTextContainer>
          <BadgeTextTwo>
            First lesson is <strong>free!</strong>
          </BadgeTextTwo>
        </Badge>
        <MainText>MUSIC LESSONS IN THE COMFORT OF YOUR HOME</MainText>
        <ArrowContainer>
          <KeyboardArrowDown
            sx={{
              color: "white",
              fontSize: 56,
              zIndex: 99,
            }}
          />
        </ArrowContainer>
      </InnerContainer>
    </Container>
  );
};

export default Intro;
