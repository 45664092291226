export const lessonItems = [
    {
        title: "PIANO",
        desc: "Piano Lessons for all ages. Lessons for any skill level and interests -choose from classical, popular, blues, jazz and more.",
        img: require("../assets/images/piano-lessons.webp")
    },
    {
        title: "GUITAR/BASS",
        desc: "We offer electric and acoustic guitar levels. Take your guitar playing to the next level with some of our specialty instructors.",
        img: require("../assets/images/guitar-lessons.webp")
    },
    {
        title: "MUSIC THEORY",
        desc: "Take your understanding of music concepts to the next level with our music theory classes.",
        img: require("../assets/images/music-theory.webp")
    },
    {
        title: "SONGWRITING",
        desc: "Learn to write chord progressions, melodies and lessons on how to use professional audio recording software",
        img: require("../assets/images/songwriting.webp")
    },
    {
        title: "VOCALS",
        desc: "Sing all you favourite tunes! Get a customized lesson experience that focuses on your specific voal goals and needs.",
        img: require("../assets/images/vocals.webp")
    },
    {
        title: "DRUMS",
        desc: "Learn to be the heartbeat of the rhythm section with our amazing drum teachers.",
        img: require("../assets/images/drums.webp")
    }
]

export const pricing = [
    {
        time: "30 MINUTES",
        cost: "$45/lesson",
        tag: "Perfect for Beginners",
    },
    {
        time: "45 MINUTES",
        cost: "$55/lesson",
        tag: "Most Popular",
    },
    {
        time: "60 MINUTES",
        cost: "$65/lesson",
        tag: "Great for Intermediates",
    },
]

export const faq = [
    {
        id: 0,
        question: 'Do I need to provide my own instrument for lessons?',
        answer: 'Yes, students will need to have their own instrument to practice in between lessons. If you do not want to buy an instrument at first renting is a great option. You can contac us about our rental program.',
    },
    {
        id: 1,
        question: 'Do you have a rental program?',
        answer: "Yes but our instrument inventory is very limited. If we don't have what we're looking for I reccomend looking at Long & McQaude's rental program.",
    },
    {
        id: 2,
        question: 'Do I have to signup for a minimum amount of lessons?',
        answer: "No you can signup for as many or as little lessons as you'd like. We are very flexible with our scheduling. We recommend weekly lessons but some students prefer bi-weekly or even monthly options.",
    },
    {
        id: 3,
        question: 'What instruments do you teach?',
        answer: "We teach a variety of instruments including piano, guitar, bass guitar, music theory, songwriting and more.",
    },
    {
        id: 4,
        question: 'Where do the lesssons take place?',
        answer: "The lessons take place in the comfort of your home. Aside from the convenience, this also allows the teacher to help create an effective practice space for the student.",
    },
    {
        id: 5,
        question: 'How long are the lessons?',
        answer: "The lessons usually range between 30, 45 and 60 minutes depending on your preference.",
    },
]