import React from "react";
import styled from "styled-components";
import { lg, md, sm, xl, xxl } from "../responsive";
import aboutImg from "../assets/images/about.webp"
import { Title } from "../assets/styles";
import { Padding } from "@mui/icons-material";

const Container = styled.div`
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  ${xl({ flexDirection: "column-reverse", height: "100%" })}
`;
const Left = styled.div`
  flex: 4;
  display: flex;
  justify-content: center;
  ${xl({ display: "none" })}
`;
const Right = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;
  ${xl({ gap: "90px" })}
  ${md({ gap: "50px" })}
  ${sm({ gap: "10px" })}
`;

const Desc = styled.p`
  width: 70%;
  line-height: 25px;
  font-size: 18px;
  font-weight: 300;
  padding: 5px;
  overflow: hidden;
  ${xxl({ fontSize: "20px" })}
  ${xl({ backgroundColor: "white", color: "black", padding: "25px" })}
  ${lg({ fontSize: "18px", width: "80%", padding: "25px" })}
`;

const Image = styled.img`
  width: 800px;
  -webkit-box-shadow: 5px 10px 15px -5px #000000;
  box-shadow: 5px 10px 15px -5px #000000;
  margin: 60px;
  border-radius: 10px;
  transform: rotate(-6deg);
`;

const BgImage = styled.img`
  width: 100%;
  position: absolute;
  top: 100;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  z-index: 1;
  display: none;
  ${xl({ display: "none" })}
`;

const About = () => {
  return (
    <Container id="about">
      <BgImage src={aboutImg} alt="" />
      <Wrapper>
        <Left>
          <Image src={aboutImg}></Image>
        </Left>
        <Right>
          <Title>OUR PHILOSOPHY</Title>
          <Desc>
            Whether we're driving, watching TV, or putting on an old vinyl
            record, music impacts our life everyday. Although music surrounds us
            all the time, many of us spend very little time getting to
            understand and appreciate the art. At Maratta Music we've been
            helping others for the last 10+ years not only learn how to play
            music but how to love it. We offer lessons in piano, guitar, bass
            guitar, music theory and more. We accept all ages and levels. With
            some of the best teachers and musicians in the GTA we are able to
            tailor a curriculum to fit your specific goals.
          </Desc>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default About;
