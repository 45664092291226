import React from 'react';
import styled from 'styled-components';
import {lessonItems} from '../assets/adminData'
import { lg, xxl } from '../responsive';
import LessonItem from './LessonItem';
import { Title } from '../assets/styles';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  overflow: hidden;
  ${xxl({width: "90%"})}
  ${lg({width: "95%"})}
`

const Lessons = () => {
  return (
    <Container id="lessons">
      <Title>LESSONS</Title>
      <Wrapper>
        {lessonItems.map(lessonItem => {
          return (
          <LessonItem title={lessonItem.title} desc={lessonItem.desc} img={lessonItem.img} key={lessonItem.title}/>
          )
        })}
      </Wrapper>
    </Container>
  )
}

export default Lessons