import React from "react";
import styled from "styled-components";
import { lg, md, xxl } from "../responsive";

const Container = styled.div`
  height: 25vh;
  width: 22vw;
  overflow: hidden;
  -webkit-box-shadow: 5px 10px 15px -5px #000000;
  box-shadow: 5px 10px 15px -5px #000000;
  border-radius: 10px;
  background-color: #eef4fc;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  ${lg({ width: "70vw", height: "15vh" })}

  &:nth-child(2) {
    height: 28vh;
    width: 25vw;
    ${lg({ width: "75vw", height: "17vh" })}
  }
`;

const PriceTitle = styled.span`
  font-size: 36px;
  font-weight: 300;
  ${xxl({ fontSize: "28px" })}
  ${lg({ fontSize: "24px" })}
`;
const PriceCost = styled.span`
  font-size: 24px;
  color: #555555;
  font-weight: 600;
  margin-bottom: 40px;
  ${lg({ marginBottom: "5px", fontSize: "20px" })}
`;
const PriceDesc = styled.span`
  font-size: 20px;
  ${xxl({ fontSize: "18px" })}
  ${xxl({ fontSize: "16px" })}
`;

const PricingItem = ({ time, cost, tag }) => {
  return (
    <Container>
      <PriceTitle>{time}</PriceTitle>
      <PriceCost>{cost}</PriceCost>
      <PriceDesc>{tag}</PriceDesc>
    </Container>
  );
};

export default PricingItem;
