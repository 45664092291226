import React, { useState } from "react";
import styled from "styled-components";
import { lg, sm, xl } from "../responsive";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const Container = styled.div`
width: 100%;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid black;
  padding-bottom: 15px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
  cursor: pointer;
`;
const Question = styled.p`
  font-size: 18px;
`;
const Answer = styled.p`
  margin-top: 15px;
  padding-left: 15px;
`;

function QuestionCard({ question, answer, open, id, handleOpen }) {

  return (
    <Container>
      <QuestionContainer>
        <QuestionWrapper onClick={() => handleOpen(id)}>
          {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          <Question>{question}</Question>
        </QuestionWrapper>
        {open && <Answer>{answer}</Answer>}
      </QuestionContainer>
    </Container>
  );
}

export default QuestionCard;
