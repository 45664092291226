import React from "react";
import styled from "styled-components";
import { sm } from "../responsive";

const Container = styled.div`
  height: 70px;
  width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 1s ease;
  z-index: 101;
  overflow: hidden;

  &.active {
    background-color: #002a5d;
  }
`;

const Left = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
`;

const Logo = styled.h1`
  color: #002a5d;
  letter-spacing: 3px;
  ${sm({ fontSize: "24px" })}

  &.active {
    color: white;
  }
`;

const Right = styled.div`
  padding: 20px;
`;

const BurgerMenu = styled.div`
  width: 32px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

const Burger = styled.span`
  width: 100%;
  height: 3px;
  background-color: #002a5d;
  transform-origin: left;
  transition: all 1s ease;

  &.active {
    &:first-child {
      background-color: white;
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      display: none;
    }
    &:last-child {
      background-color: white;
      transform: rotate(-45deg);
    }
  }
`;

const Topbar = ({ menuOpen, setMenuOpen }) => {
  return (
    <Container className={menuOpen ? "active" : ""}>
      <Left>
        <Logo className={menuOpen ? "active" : ""}>MARATTA MUSIC</Logo>
      </Left>
      <Right>
        <BurgerMenu
          onClick={() => setMenuOpen(!menuOpen)}
          className={menuOpen ? "active" : ""}
        >
          <Burger className={menuOpen ? "active" : ""} />
          <Burger className={menuOpen ? "active" : ""} />
          <Burger className={menuOpen ? "active" : ""} />
        </BurgerMenu>
      </Right>
    </Container>
  );
};

export default Topbar;
