import React from "react";
import styled from "styled-components";
import { lg, sm, xl } from "../responsive";

const Container = styled.div`
  width: 27vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;
  z-index: 100;

  ${xl({ width: "35vw" })}
  ${lg({ width: "50vw" })}
  ${sm({ width: "80vw" })}

  &.active {
    right: 0;
    background-color: #002a5d;
  }
`;

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 30px;
  font-weight: 300;
  color: white;
  width: 70%;
`;

const MenuItem = styled.li`
  margin-bottom: 20px;
`;
const MenuAnchor = styled.a`
  font-size: 30px;
  color: inherit;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    font-weight: 600;
  }
`;

const Menu = ({ menuOpen, setMenuOpen }) => {
  return (
    <Container className={menuOpen ? "active" : " "}>
      <MenuList>
        <MenuItem>
          <MenuAnchor onClick={() => setMenuOpen(!menuOpen)} href="#home">
            Home
          </MenuAnchor>
        </MenuItem>
        <MenuItem>
          <MenuAnchor onClick={() => setMenuOpen(!menuOpen)} href="#about">
            Our Philosophy
          </MenuAnchor>
        </MenuItem>
        <MenuItem>
          <MenuAnchor onClick={() => setMenuOpen(!menuOpen)} href="#pricing">
            Pricing
          </MenuAnchor>
        </MenuItem>
        <MenuItem>
          <MenuAnchor onClick={() => setMenuOpen(!menuOpen)} href="#faq">
            FAQ
          </MenuAnchor>
        </MenuItem>
        <MenuItem>
          <MenuAnchor onClick={() => setMenuOpen(!menuOpen)} href="#contact">
            Contact
          </MenuAnchor>
        </MenuItem>
      </MenuList>
    </Container>
  );
};

export default Menu;
