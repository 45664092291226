import React from "react";
import styled from "styled-components";
import { lg, md, sm, xl, xxl } from "../responsive";

const Container = styled.div`
  height: 30vh;
  width: 22vw;
  overflow: hidden;
  -webkit-box-shadow: 5px 10px 15px -5px #000000;
  box-shadow: 5px 10px 15px -5px #000000;
  border-radius: 10px;
  background-color: #002a5d;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  position: relative;

  ${xxl({ width: "26vw" })}
  ${lg({ width: "40vw", height: "20vh", gap: "15px" })}
  ${sm({ width: "90vw", height: "10vh", gap: "0" })}
`;

const BgImage = styled.img`
  width: 22vw;
  max-height: 30vh;
  object-fit: cover;
  opacity: 0.2;
  position: absolute;

  ${xxl({ width: "26vw" })}
  ${lg({ width: "40vw" })}
`;

const LessonTitle = styled.h2`
  z-index: 2;
  color: whitesmoke;
  ${sm({ fontSize: "18px" })}
`;
const LessonDesc = styled.p`
  z-index: 2;
  color: whitesmoke;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  ${xl({ fontSize: "18px" })}
  ${md({ fontSize: "16px", padding: "2px 4px" })}
  ${sm({ fontSize: "13px" })}
`;
const LessonItem = ({ title, desc, img }) => {
  return (
    <Container>
      <BgImage src={img} />
      <LessonTitle>{title}</LessonTitle>
      <LessonDesc>{desc}</LessonDesc>
    </Container>
  );
};

export default LessonItem;
