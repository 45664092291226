import styled, { css } from "styled-components";
import { lg, sm, xl, xxl } from "../responsive";

//TITLE
export const Title = styled.h2`
  text-align: center;
  margin-top: 10px;
  font-size: 56px;
  font-weight: 200;
  z-index: 99;
  ${xl({ marginTop: 0 })}
  ${sm({ fontSize: "40px" })}

  ${props =>
    props.$bold &&
    css`
      ${lg({ color: "white", fontWeight: 600 })}
    `};
`;
